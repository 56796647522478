import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';
import Helmet from 'react-helmet';
import marked from 'marked';
import cn from 'classnames';
import { toggleFavoriteGameById } from '@actions/favorite';
import BasePage from '@pages/BasePage';
import Banners, { selectBannersProps } from '@components/Banners';
import GameGrid, { selectGameGridProps } from '@components/GameGrid';
import withRouteData from '@utils/withRouteData';
import CasinoCategoryMenu from '@components/CasinoCategoryMenu';
import NoGamesMessage from '@components/NoGamesMessage';
import { Translate, Icon } from 'tg-core-components';
import {
  getTopGames,
  getTopGamesWager,
  getTopGamesUWC,
} from 'tg-core-redux/lib/modules/event/action';
import PayNPlay from '@components/PayNPlay';
import GameGroupTitle from '@components/GameGroupTitle';
import PageBuilder from '@components/PageBuilder';
import ProductBlock from '@components/ProductBlock';
import selectGames from '@selectors/selectGames';
import selectLastPlayedGames from '@selectors/selectLastPlayedGames';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import { getConfig } from '@config';
import selectTopGames from '@selectors/selectTopGames';
import {
  getGamesByCategory,
  getCategoriesBySearchTerm,
  getCustomCategoryGames,
  getFuseSearch,
} from '@utils/casino';
import { getTopGamesPromises } from '@utils/game';
import { Link } from 'react-router-dom';
import selectCountry from '@selectors/selectCountry';

import './style.css';
import '@styles/categories-nav.css';

@withRouteData
@connect(
  state => ({
    pages: state.content.pages.data,
    sections: state.content.sections.data,
    games: selectGames(state),
    sets: state.content.sets.data,
    productBlocks: state.responsible_gaming.productBlocks,
    bannersProps: selectBannersProps(state),
    gameGridProps: selectGameGridProps(state),
    lastPlayedGames: selectLastPlayedGames(state),
    favoriteGames: selectFavoriteGames(state),
    jurisdiction: state.app.jurisdiction,
    isAuthenticated: state.player.isAuthenticated,
    ipCountry: state.app.ipCountry,
    topGames: selectTopGames(state),
    jackpots: state.event.jackpots,
    device: state.app.device,
    country: selectCountry(state),
  }),
  { toggleFavoriteGameById, getTopGames, getTopGamesWager, getTopGamesUWC }
)
class CasinoContainer extends Component {
  state = {
    pagination: 1,
    searchTerm: '',
    isSearchOpen: false,
  };
  fuse = null;

  componentDidMount() {
    if (this.props.match.params.category) {
      setTimeout(() => this.scrollToGames(), 1);
    }

    const hasTopGamesCategory = this.props?.sets
      .find(s => s.identifier === 'casino-index-categories')
      ?.items.find(i => i.identifier === 'top-games');

    if (hasTopGamesCategory) {
      Promise.all(getTopGamesPromises(this.props));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.category !== prevProps.match.params.category) {
      setTimeout(() => this.scrollToGames(), 1);
    }
  }

  scrollToGames() {
    const gameGrid = document.querySelector(
      '.main-content-container > .container > .content'
    );
    const header = document.querySelector('.header-wrapper');
    const heightToScroll = gameGrid?.offsetTop - header?.offsetHeight;
    window.scroll({ top: heightToScroll, left: 0, behavior: 'smooth' });
  }

  loadMoreGames = () =>
    this.setState({ pagination: this.state.pagination + 1 });

  render() {
    const { pagination, searchTerm, isSearchOpen } = this.state;

    let games = this.props.games;

    const {
      pages,
      match,
      sets,
      sections,
      toggleFavoriteGameById,
      productBlocks,
      bannersProps,
      gameGridProps,
      favoriteGames,
      topGames,
      jackpots,
      device,
      jurisdiction,
      ipCountry,
      lastPlayedGames,
      country,
    } = this.props;

    this.fuse = getFuseSearch(games);

    const config = getConfig(jurisdiction, ipCountry);

    const liveCasino = match.path.includes('live-casino');

    const baseUrl = liveCasino ? '/live-casino' : '/casino';

    const page =
      pages.find(p => '/' + p.url === match.url) ||
      pages.find(p => p.identifier === (liveCasino ? 'live-casino' : 'casino'));

    const hasLastPlayed = lastPlayedGames.length > 0;
    const hasFavoriteGames = favoriteGames.length > 0;

    const navbarItems = sets
      .find(
        s =>
          s.identifier === (liveCasino ? 'live-casino-navbar' : 'casino-navbar')
      )
      ?.items?.filter(i => {
        if (i.identifier === 'last-played-games' && !hasLastPlayed) {
          return false;
        }

        if (i.identifier === 'my-games' && !hasFavoriteGames) {
          return false;
        }

        return true;
      });
    const addTo = item => ({
      ...item,
      to: {
        pathname: `${baseUrl}/${item.identifier}`,
        state: { scrollToTop: false },
      },
      items: item.items ? item.items.map(addTo) : null,
    });
    const navbarWithTo = navbarItems?.map(addTo);
    const banners = sets.find(
      s =>
        s.identifier === (liveCasino ? 'live-casino-banners' : 'casino-banners')
    );
    const indexCategories = sets.find(
      s =>
        s.identifier ===
        (liveCasino
          ? 'live-casino-index-categories'
          : 'casino-index-categories')
    );

    const preIndexCategories = sets.find(
      s =>
        s.identifier ===
        (liveCasino
          ? 'live-casino-pre-index-categories'
          : 'casino-pre-index-categories')
    );

    if (match.params.category === 'new-games') {
      games = games
        .map(g => g)
        .sort(
          (a, b) =>
            new Date(b._sysCreatedAt).getTime() -
            new Date(a._sysCreatedAt).getTime()
        );
    }

    const productBlockSection = sections.find(
      s => s.identifier === 'block-feature-casino'
    );
    const inlinePnp = sections.find(s => s.identifier === 'inline-pnp');

    const categoryItems = indexCategories.items.find(
      s => s.identifier === 'categories'
    );

    const categoryFromParams = indexCategories.items.find(
      c => c.identifier === match.params.category
    );

    const isSearching = this.state.searchTerm.length > 0;

    const categoryGames =
      getGamesByCategory(games, match.params.category) &&
      !categoryFromParams?.config.customCategory
        ? getGamesByCategory(
            games,
            match.params.category,
            lastPlayedGames,
            favoriteGames,
            categoryFromParams?.config,
            topGames
          )
        : categoryFromParams?.items?.length > 0
        ? getCustomCategoryGames(categoryFromParams?.items, games)
        : [];

    const searchGames = this.fuse?.search(searchTerm).map(game => game.item);
    const searchCategories = getCategoriesBySearchTerm(
      categoryItems,
      searchTerm
    );
    const searchResult = isSearching && [...searchGames, ...searchCategories];

    const gameGridGames = categoryGames.slice(0, 20 * pagination);

    let waypoint = null;
    if (gameGridGames.length !== categoryGames.length)
      waypoint = (
        <Waypoint
          topOffset="150px"
          key={pagination}
          onEnter={this.loadMoreGames}
        />
      );

    const hasBanner = banners?.items?.length > 0;

    const totalJackpot =
      jackpots && jackpots.reduce((prev, curr) => prev + curr.Amount, 0);

    const currency = (jackpots && jackpots[0]?.Currency) || 'EUR';

    const renderBanner = () => (
      <div ref={r => (this.Banner = r)} className="main-slider container">
        <Banners banners={banners} {...bannersProps} />
      </div>
    );

    if (
      productBlocks?.find(block => block.Product === 'Casino') ||
      config?.block?.casino
    )
      return <ProductBlock productBlockSection={productBlockSection} />;

    return (
      <BasePage
        page={page}
        className={cn('CasinoContainer', {
          'CasinoContainer--has-banner': hasBanner,
          'CasinoContainer--is-live': liveCasino,
        })}>
        <Helmet
          bodyAttributes={{
            'is-searching-casino': isSearching,
          }}
        />
        <main className="container">
          {inlinePnp ? (
            <div className="pnp-and-banner">
              <PayNPlay isInline={true} />
              {hasBanner && renderBanner()}
            </div>
          ) : (
            <Fragment>{hasBanner && renderBanner()}</Fragment>
          )}

          <div className="content">
            <div>
              {preIndexCategories?.config?.type === 'pageBuilder' && (
                <PageBuilder
                  toggleFavoriteGameById={toggleFavoriteGameById}
                  items={preIndexCategories ? preIndexCategories.items : []}
                  games={games}
                  totalJackpot={totalJackpot}
                  currency={currency}
                  device={device}
                  baseUrl={baseUrl}
                />
              )}
            </div>
            <CasinoCategoryMenu
              items={navbarWithTo}
              activeCategory={match.params.category || 'overview'}
              searchTerm={searchTerm}
              onSearchChange={value => {
                this.setState({ searchTerm: value });

                if (config?.casino?.scrollOnSearch) {
                  setTimeout(() => {
                    this.scrollToGames();
                  }, 0);
                }
              }}
              isSearchOpen={isSearchOpen}
              onToggleSearch={() => {
                this.setState({ isSearchOpen: !isSearchOpen, searchTerm: '' });
              }}
              showSearch
            />

            {(!match.params.category || match.params.category === 'overview') &&
            !isSearching ? (
              <PageBuilder
                toggleFavoriteGameById={toggleFavoriteGameById}
                items={indexCategories ? indexCategories.items : []}
                games={games}
                lastPlayedGames={lastPlayedGames}
                totalJackpot={totalJackpot}
                currency={currency}
                device={device}
                baseUrl={baseUrl}
              />
            ) : (
              <div className="GridContainer">
                <div className="GridContainer__go-back">
                  <Link to={baseUrl}>
                    <Icon icon="chevron-left" />
                    <Translate id="label.go-back" defaultMessage="Go back" />
                  </Link>
                </div>

                {!isSearching && (
                  <div className="grid-header">
                    <GameGroupTitle
                      country={country}
                      identifier={match.params.category}
                      icon={
                        categoryItems?.items?.find(
                          c => c.identifier === match.params.category
                        )?.icon
                      }
                    />
                  </div>
                )}
                {(searchResult || gameGridGames || []).length > 0 ? (
                  <GameGrid
                    className={cn('GameGrid--extra-margin-on-mobile', {
                      'gamegrid-extra-margin-top': isSearching,
                    })}
                    items={searchResult || gameGridGames}
                    toggleFavoriteGameById={toggleFavoriteGameById}
                    category={categoryFromParams}
                    closeSearch={() => {
                      this.setState({ searchTerm: '', isSearchOpen: false });
                    }}
                    {...gameGridProps}
                  />
                ) : (
                  <div className="no-games">
                    <NoGamesMessage
                      linkLocation={baseUrl}
                      onClickLink={() => {
                        this.setState({ searchTerm: '', isSearchOpen: false });
                        this.scrollToGames();
                      }}
                    />
                  </div>
                )}

                {waypoint}
                {waypoint && (
                  <div className="loading-games h3">
                    <Translate
                      id="message.loading"
                      defaultMessage="Loading..."
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {page && page.content && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: marked(page.content) }}
            />
          )}
        </main>
      </BasePage>
    );
  }
}

export default CasinoContainer;
