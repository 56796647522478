// eslint-disable-next-line
export default (CURRENT_ENV, country) => {
  return {
    config: 'common',
    defaultJurisdiction: 'sga',
    CURRENT_ENV,
    type: 'pnp',
    indexContainer: 'IndexContainerPnp',
    skinId: CURRENT_ENV === 'prod' ? 66 : 132,
    defaultLocale: 'sv-SE',
    MERCHANT_ID: CURRENT_ENV === 'prod' ? 100014043 : 100014984,
    content: {
      space: '89k88QbkTZkw',
      host: 'https://delivery2.objectic.io',
      access_token: '1021|XY3XHFZDuDhLa9BzgH6bZURaOVIQPQt2wAe0JP0D',
      preview_host: 'https://preview.objectic.io',
      preview_access_token: '1021|XY3XHFZDuDhLa9BzgH6bZURaOVIQPQt2wAe0JP0D',
      environment: CURRENT_ENV === 'prod' ? 'master' : 'stage',
    },
    games: {
      space: 'jIWXpQOLRVBo',
      host: 'https://delivery2.objectic.io',
      access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
      preview_host: 'https://preview.objectic.io',
      preview_access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
      environment: CURRENT_ENV === 'prod' ? 'master' : 'master',
      gameIdKey: CURRENT_ENV === 'prod' ? 'tg-pz' : 'tg-pz-stage',
    },
    axiosCache: {
      duration: 60, // 1 minute
      whitelist: ['delivery2.objectic.io', '/event-feed/jackpots'],
    },
    jurisdictions: [
      {
        key: 'sga',
        name: 'supersnabbt.se (se)',
        domain: CURRENT_ENV === 'prod' ? 'www.supersnabbt.se' : null,
      },
    ],
    tgplay: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://play.supersnabbt.se'
          : 'https://play-togethergaming-new-config-stage.azurewebsites.net',
      skin: 'supersnabbt_pz',
      standalone: true,
    },
    aleacc: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://api.supersnabbt.se'
          : 'https://api-stage-supersnabbt.codebet.dev',
    },
    piqCashier: {
      predefinedAmounts: {
        eur: [50, 100, 200],
        cad: [50, 100, 200],
        nzd: [50, 100, 200],
        nok: [500, 1000, 2000],
        sek: [500, 1000, 2000],
      },
      showAmountLimits: true,
      showFee: false,
      showBonusCode: true,
      autoSetIframeProviderHeight: false,
      allowMobilePopup: true,
      pollLimit: 7,
      hardClose: true,
      showCookiesWarning: false,
      theme: {
        buttons: {
          color: '#feb903',
        },
        loader: {
          color: '#fff',
        },
        headings: {
          color: '#fff',
        },
        labels: {
          color: '#fff',
        },
        cashierbackground: {
          color: '#227aba',
        },
        margin: {
          size: '10px',
        },
      },
      css: `
      #cashier .submit-button {
        background: linear-gradient(#feb903, #ffb000);
        color: #0b123a;
      }
      `,
    },
    devcode: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://api.paymentiq.io/paymentiq/api'
          : 'https://test-bo.paymentiq.io/paymentiq/api',
      oauth_host:
        CURRENT_ENV === 'prod'
          ? 'https://backoffice.paymentiq.io/paymentiq/oauth'
          : 'https://test-api.paymentiq.io/paymentiq/oauth',
      oauth_provider: 'trustly',
      oauth_clientid:
        CURRENT_ENV === 'prod'
          ? 'e188a7f3d21a4708bd7bc0b4b9fead16'
          : '9232f63b5d00447daf8f397634aad5e7',
      oauth_redirecturl:
        CURRENT_ENV === 'prod'
          ? 'https://www.supersnabbt.se/nemid.html'
          : 'https://stage-supersnabbt.codebet.dev/nemid.html',

      oauth_ui_friendly: 'bethard-1',
    },
    metric: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://supersnabbt.betpump.com'
          : 'https://supersnabbt.superlivepreprod.com',
    },
    authenticgaming: {
      host:
        CURRENT_ENV === 'prod'
          ? 'https://game.itsreal.live//game/loader.aspx?'
          : 'http://game.authenticstage.live/game/loader.aspx?',
      operatorId: 38,
    },
    quickDeposit: {
      enabled: false,
      host: 'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/tg-quickdeposit.bundle.js',
      successUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/success.html',
      failureUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/failure.html',
      cancelUrl:
        'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/cancel.html',
    },

    chat: {
      provider: 'freshchat',
    },

    freshChat: {
      host: "https://bethard.freshchat.com",
      token: "c44b51b7-1e7b-4df7-8009-72478339d0df",
      widgetUuid: "87b32613-4bea-440a-afc1-c2347966796e",
      brand: 'supersnabbt',
      appName: 'Supersnabbt',
      appImage:
        'https://delivery.objectic.io/Ms1C7C4hQfDMy4p0qPh5EE/89k88QbkTZkw/aGWY0qpVQ17dKXMRGXepqYrWICuH9NsQW0hptEFJ.svg',
      tags: ['welcomesupersnabbt', 'issuesupersnabbt'],
      config: {
        headerProperty: {
        },
      },
    },

    /**
     * Visibility configuration for user registration.
     * @description
     * Current available elements for the array: ['email', 'phone']
     */
    userFields: ['email', 'phone'],

    /**
     * How the index page features should be displayed
     * @description
     * Current available options are 'row', 'slider', 'textSlider' and 'iconSlider'
     * Default :
     * desktop: 'row',
     * mobile: 'slider'
     */
    indexFeatures: {
      desktop: 'row',
      mobile: 'slider',
    },

    /**
     * Custom logo url
     */
    logoUrl: '/skin/images/logo.svg',

    /**
     * If the sign in/fetch balance button should be displayed in header or base page.
     * @description
     * Default is true which means it should be in header.
     * showFetchBalanceIndexHeader: true
     */
    showFetchBalanceIndexHeader: true,

    showBonusSelector: true,
    globalNavContainer: false,

    products: ['casino', 'payments'],

    hideOnScroll: {
      footer: false,
    },

    /**
     * Show sticky footer on desktop
     */
    showStickyFooter: false,

    /**
     * Show sticky footer sign up CTA
     */
    stickyFooterButton: false,

    /**
     * Show sticky footer on the index page, will also hide the hamburger
     */
    stickyFooterOnIndex: true,

    /*
     * Choose if support button in header/dashboard should navigate to page or open support widget
     */
    openSupportWidget: {
      header: false,
      sidebar: true,
    },

    /**
     * Casino options
     * @property layout Game grid layout (slider, grid)
     * @property searchStyle Show casino categories (categories, noCategories)
     */
    casino: {
      layout: 'slider',
      casinoCategoryMenuStyle: 'categories',
      liveCasinoData: true,
    },

    redirectCasino: true,
    preGameView: {
      signedIn: true,
      signedOut: true,
    },

    /**
     * Options for communication consent checkbox on details step.
     */
    communicationConsent: {
      enabled: true,
      values: [
        'BonusEmails',
        'BonusSMSs',
        'NewsEmails',
        'NewsSMSs',
        'Phone',
        'Casino',
        'Sportsbook',
      ],
    },

    visitedCookieName: 'tg-page-visited',
    visitedCookieMaxAge: 365 * 24 * 60 * 60 * 1000,
    gameHistoryName: 'tg-game-history',
    btagCookie: 'tg-btag',
    defaultCurrency: 'SEK',

    GTM_ID: 'GTM-W8J7QG87',

    redirects: [
      {
        source: /^\/unsub/,
        target: () => '/fetch-balance?ref_url=dashboard/subscriptions',
      },
      {
        source: '/deposit',
        target: () => '/start-playing',
      },
      {
        source: '/withdraw',
        target: () => '/#withdraw',
      },
      {
        source: /^\/(sign-in|login)$/,
        target: () => '/fetch-balance',
      },
      {
        source: '/sign-up',
        target: () => '/#sign-up',
      },
      {
        source: '/dashboard',
        target: () => '/#dashboard',
      },
      {
        source: /^\/(dashboard\/activate|activate)$/,
        target: () => '/#dashboard/activate',
      },
      {
        source: '/dashboard/responsible-gaming',
        target: () => '/#dashboard/responsible-gaming',
      },
      {
        source: '/dashboard/subscriptions',
        target: () => '/#dashboard/subscriptions',
      },
      {
        source: '/dashboard/verify',
        target: () => '/#dashboard/verify',
      },
      {
        source: '/dashboard/bonus',
        target: () => '/#dashboard/bonus',
      },
      {
        source: '/dashboard/history',
        target: () => '/#dashboard/history',
      },
      {
        source: '/dashboard/messages',
        target: () => '/#dashboard/messages',
      },
      {
        source: '/dashboard/edit-profile',
        target: () => '/#dashboard/edit-profile',
      },
      {
        source: '/dashboard/change-password',
        target: () => '/#dashboard/change-password',
      },
      {
        source: '/sbtech/sbtech.js',
        target: () => '/sbtech.js',
      },
      {
        source: '/sports',
        target: () => '/casino',
      },
    ],
    /**
     * @param {Request} [req] Provide the request object if this function is being used on the server
     * @returns {(string|boolean)}
     */
    cookieDomain: (req) => {
      if (!__CLIENT__ && !req) return false;
      return (__CLIENT__ ? window.location.href : req.get('host')).includes(
        'supersnabbt.se'
      )
        ? '.supersnabbt.se'
        : false;
    },
  };
};
